
import Vue, { Component } from "vue";
import BaseDialog from "@/components/BaseDialog.vue";
export default Vue.extend({
  props: ["item", "value"],
  methods: {
    async requestPassword() {
      if (!(this.$refs.form as any).validate()) {
        return;
      }
      if (
        await this.$store.dispatch(
          "authentication/requestNewPassword",
          this.email
        )
      ) {
        this.sent = true;
      }
    }
  },
  components: {
    "base-dialog": BaseDialog
  },
  computed: {
    dialog: {
      get(this: any): boolean {
        return this.value;
      },
      set(this: any, value: boolean): void {
        this.$emit("input", value);
        this.email = "";
        this.sent = false;
      }
    }
  },
  watch: {},
  data() {
    return {
      email: "",
      sent: false
    };
  }
});
