
import Vue from "vue";
import RotateButton from "@/components/RotateButton.vue";

export default Vue.extend({
  name: "login-form",
  data() {
    return {
      username: "",
      password: "",
      submitted: false,
      keepMeSignedIn: false
    };
  },
  computed: {
    loggingIn(): boolean {
      return this.$store.state.authentication.status.loggingIn;
    }
  },
  created(): void {
    // reset login status
    this.$store.dispatch("authentication/logout");
  },
  methods: {
    userSignIn(): void {
      this.submitted = true;
      const { username, password, keepMeSignedIn } = this;
      const { dispatch } = this.$store;

      if (username && password) {
        dispatch("authentication/login", {
          username,
          password,
          keepMeSignedIn
        });
      }
    }
  },
  components: {
    "rotate-btn": RotateButton
  }
});
