
import Vue from "vue";
import LayoutPlain from "@/layouts/LayoutPlain.vue";
import LoginForm from "@/components/LoginForm.vue";
import RequestNewPasswordModal from "@/components/RequestNewPasswordModal.vue";
const Component = Vue.extend({
  created(): void {
    this.$emit("update:layout", LayoutPlain);
  },
  components: {
    "login-form": LoginForm,
    "new-password-modal": RequestNewPasswordModal,
  },
  data() {
    return {
      dialog: false,
    };
  },
});
export default Component;
