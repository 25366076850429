
import Vue from "vue";

const Component = Vue.extend({
  name: "LayoutPlain",
  components: {},
  computed: {},
  watch: {},
  data() {
    return {};
  }
});
export default Component;
